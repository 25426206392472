import {Form, FormikBag, FormikProps, withFormik} from 'formik';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules-react';
import styled from 'styled-components';

import {
  actions as userAdminActions,
  selectors as userAdminSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import Nebula from 'Common/components/Layout/Nebula';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {convertHeight, roundHeight} from 'Common/helpers/convertHeight';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {FieldSpecificErrors} from 'Common/models/FieldSpecificErrors';
import {IAppState} from 'Common/store/IAppState';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {IBreed} from 'Dictionaries/models/IBreed';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IMarking} from 'Dictionaries/models/IMarking';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {deleteImagesFromGallery} from 'Gallery/helpers/deleteImagesFromGallery';
import {actions as galleryActions, selectors as gallerySelectors} from 'Gallery/store';
import {GalleryModule} from 'Gallery/store/galleryModule';
import {HorseModule} from 'Horse/store/horse/horseModule';
import {
  getValidationScheme,
  IEditHorseFields,
  IFormValues,
  IHorseEditFormFields,
  initialValue,
} from 'HorseProfile/components/HorseProfileForm/HorseProfileFormInnerValidation';
import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import * as selectors from 'HorseProfile/store/horseProfileForm/selectors';

import {AdminUsersModule} from 'Admin/AdminDashboard/store/adminUsers/users/adminUsersModule';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {useToast} from 'Common/helpers/hooks/useToast';
import {retrieveImageError} from 'Common/helpers/retrieveImageError';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import Loading from 'Loading/components/Loading';
import {FormType} from './constants';
import getHorseEditFields from './converters/getHorseEditFields';
import {
  AdditionalInformation,
  Commerciality,
  Family,
  GeneralInformation,
  HealthProfile,
  HorseImages,
  Privacy,
  Registries,
  SelectOwner,
} from './parts';
import {withHorseMapFieldPropsToRequest} from 'Common/components/FormFields/HorseMapField/withHorseMapFieldProps';
import {Checkbox} from 'Common/components/Controls';
import {CheckboxField, SelectField} from 'Common/components/FormFields';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {castToOption, getStringKeysOption} from '../../../Common/helpers/OptionHelper';
import {AnimalType} from '../../../Common/constants/AnimalType';

function convertErrors(serverErrors: FieldSpecificErrors<ServerErrors>): FieldSpecificErrors<IFormValues> {
  const heightError = serverErrors['heightRequest.height'] || serverErrors['heightRequest'];
  const heightUnitError = serverErrors['heightRequest.heightUnit'];
  return {
    ...serverErrors,
    ...(heightError && {height: heightError}),
    ...(heightUnitError && {heightUnit: heightUnitError}),
  };
}

type ServerErrors = Record<string, string>;
type FormErrors = IHorseEditFormFields;

const formHeaderByType: Record<FormType.create | FormType.edit | FormType.show, string> = {
  create: 'Add a new animal',
  edit: 'Update animal',
  show: 'Animal information',
};

const OwnerInfo = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

interface IExternalDictionaries {
  inconclusiveHealthIssueDictionary: ISimpleDictionary<IInconclusiveHealthIssue>;
  horseColorsDictionary: ISimpleDictionary<IHorseColor>;
  breedDictionary: ISimpleDictionary<IBreed>;
  disciplineDictionary: ISimpleDictionary<IDiscipline>;
  markingDictionary: ISimpleDictionary<IMarking>;
  associationDictionary: ISimpleDictionary<IAssociation>;
  commercialTypeDictionary: ISimpleDictionary<ICommercialType>;
}

interface IExternalProps extends IExternalDictionaries {
  horse?: IEditHorseFields | null;
  externalError?: string;
  isLoading?: boolean;
  type: FormType;
  isAdmin?: boolean;
  ownerId?: number;
  onSubmit(fields: IHorseEditFormFields): Promise<number>;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function HorseProfileFormik(props: AllProps) {
  const {
    getGallery,
    inconclusiveHealthIssueDictionary,
    horseColorsDictionary,
    breedDictionary,
    disciplineDictionary,
    markingDictionary,
    associationDictionary,
    commercialTypeDictionary,
    type,
    horse,
    isLoading,
    isLoadingFormData,
    externalError,
    status,
    isSubmitting,
    isAdmin,
    values,
    setFieldValue,
    touched,
    galleryLoading,
    getUserDetails,
    userDetails,
    userDetailsLoading,
    ownerId,
  } = props;

  const {
    actions: {getItems: getInconclusiveHealthIssue},
  } = inconclusiveHealthIssueDictionary;
  const {
    actions: {getItems: getHorseColors},
  } = horseColorsDictionary;
  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;
  const {
    actions: {getItems: getDisciplines},
  } = disciplineDictionary;
  const {
    actions: {getItems: getMarkings},
  } = markingDictionary;
  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;
  const {
    actions: {getItems: getCommercialTypes},
  } = commercialTypeDictionary;

  const header = formHeaderByType[type];

  const ownerAlreadyExists = !!userDetails;

  const {addToast} = useToast(1);

  useEffect(() => {
    if (ownerId) {
      if (!ownerAlreadyExists) {
        getUserDetails(ownerId);
      }
    }

    setFieldValue('userId', ownerId || userDetails?.id);
  }, [setFieldValue, userDetails, ownerAlreadyExists, ownerId, getUserDetails]);

  useEffect(
    () => {
      getBreeds();
      getDisciplines();
      getInconclusiveHealthIssue();
      getHorseColors();
      getMarkings();
      getAssociations();
      getCommercialTypes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const horseId = horse?.id;
  useEffect(() => {
    if (horseId) {
      getGallery(horseId, isAdmin);
    }
  }, [isAdmin, horseId, getGallery]);

  const submitError = status;
  const fieldErrors = useMemo(() => {
    return getFieldErrors<ServerErrors, FormErrors>(submitError, convertErrors);
  }, [submitError]);

  const commonError = useMemo(() => {
    if (!!fieldErrors) {
      return null;
    }
    return submitError ? getCommonErrors(submitError) || String(submitError) : null;
  }, [submitError, fieldErrors]);

  useEffect(() => {
    if (commonError) {
      addToast(commonError, 'error');
    }
  }, [addToast, commonError]);

  useEffect(() => {
    if (fieldErrors) {
      Object.entries(fieldErrors).forEach(([key, value]) => {
        if (key && values[key]) {
          addToast(`${key}: ${value}`, 'error');
        }
      });
    }
  }, [fieldErrors, addToast, values]);

  const [prevHeightUnit, setPrevHeightUnit] = useState<HeightUnits>(values.heightUnit);
  const [heightValue, setHeightValue] = useState(values.height);

  useEffect(() => {
    const newHeightUnit = HeightUnits[values.heightUnit];
    setPrevHeightUnit(newHeightUnit);

    if (!touched.heightUnit) {
      return;
    }
    const valueToConvert =
      !heightValue || values.height !== Math.round(heightValue * 100) / 100 ? values.height : heightValue;

    const convertedValue = convertHeight(valueToConvert || 0, prevHeightUnit, newHeightUnit);

    setFieldValue('height', roundHeight(convertedValue, newHeightUnit));
    setHeightValue(convertedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.heightUnit, prevHeightUnit, heightValue, setFieldValue]);

  const isFetchingData =
    (ownerId && userDetailsLoading.isRequesting) || isLoadingFormData || isLoading || galleryLoading.isRequesting;
  const isHideSubmit = type === FormType.show || !props.onSubmit;
  const isDisableSubmit = !props.isValid || !!externalError || isFetchingData;

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isFetchingData && <Loading />}
          <Nebula active={type === FormType.show || isFetchingData || isSubmitting}>
            {isAdmin && type === FormType.create && !ownerId && !userDetails && <SelectOwner />}
            {isAdmin && type === FormType.create && userDetails && (
              <FieldControlContainer label="Owner" isRequired={true}>
                <OwnerInfo>{`${userDetails?.name} (${userDetails?.email})`} </OwnerInfo>
              </FieldControlContainer>
            )}

            <SelectField
              isRequired={true}
              name="animalType"
              label="Animal Type"
              options={getStringKeysOption(AnimalType)}
              memoized={true}
            />

            <GeneralInformation
              disciplines={props.disciplines.filter((discipline) => discipline.animalType === values.animalType)}
              breeds={props.breeds.filter((breed) => breed.animalType === values.animalType)}
              colors={props.colors.filter((color) => color.animalType === values.animalType)}
              markings={props.markings.filter((marking) => marking.animalType === values.animalType)}
              animalType={values.animalType}
            />
            <Commerciality
              commercialTypes={props.commercialTypes}
              values={values.commercialTypes}
              selectedGender={values.gender}
            />
            <Registries associations={props.associations} registries={values.registries || []} />
            <Family
              associations={props.associations}
              sirRegistries={values.parentage?.sire?.registries || []}
              damRegistries={values.parentage?.dam?.registries || []}
            />
            <HorseImages />
            <AdditionalInformation />
            <HealthProfile healthIssues={props.healthIssues} values={values.healthProfile} />
            <Privacy disabled={!!horse?.isGeneticDataHidden} />
            {isAdmin && type === FormType.edit && (
              <>
                <FormControlContainer title={'Celebrity Horses Like Me'}>
                  <CheckboxField name="isCelebrity" label="Celebrity Horse " />
                  <FieldHint style={{marginLeft: 32, marginBottom: 16}}>
                    Toggling this option will affect whether this horse is identified as a "celebrity" while the Horses
                    Like Me report is being processed.
                  </FieldHint>
                </FormControlContainer>
              </>
            )}
          </Nebula>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{externalError}</ErrorMessage>
          <ModalWindowButton type="submit" hidden={isHideSubmit} disabled={isDisableSubmit} isLoading={isSubmitting}>
            Save horse
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  if (formikBag.props.type === FormType.show) {
    return;
  }
  const {gallery, ...horseFields} = values;
  const {
    onSuccess,
    onSubmit,
    isAdmin,
    deleteMediaFromGallery,
    addAvatar,
    deleteAvatar,
    addImages,
    addVideo,
    horse,
    gallery: originGallery,
  } = formikBag.props;
  formikBag.setStatus(null);

  const userId = isAdmin ? horseFields.userId || horse?.owner?.id : undefined;
  const isCelebrity = isAdmin ? horseFields.isCelebrity : undefined;

  const uploadMedia = async (horseId: number, media: IMediaResource, isAdmin?: boolean) => {
    if (media.type === MediaType.Image) {
      if (media.isLocal && media.file) {
        await addImages(horseId, [media], isAdmin);
      }
    }
    if (media.type === MediaType.Video) {
      await addVideo(horseId, media, isAdmin);
    }
  };

  if (isAdmin && !userId) {
    formikBag.setStatus('Empty user id when updating');
    return;
  }

  // Save the horse
  let horseId: number | null = null;
  try {
    horseId = await onSubmit(withHorseMapFieldPropsToRequest(horseFields));
    // we need reset submitting because after it we get new horse at state and with enableReinitialize formik set form to initial
    formikBag.setSubmitting(true);
  } catch (error) {
    formikBag.setStatus(error);
    return;
  }

  // Delete or upload avatar
  try {
    const currentAvatar = horseFields.avatar;

    if (!currentAvatar) {
      await deleteAvatar(horseId, isAdmin);
    }

    if (currentAvatar?.isLocal && currentAvatar?.file) {
      await addAvatar(horseId, currentAvatar, isAdmin);
    }
  } catch (e) {
    formikBag.setFieldError('avatar', retrieveImageError(e));
    return;
  }

  // Upload gallery
  try {
    for (const media of values.gallery || []) {
      if (!originGallery?.some((x) => x.id === media.id)) {
        await uploadMedia(horseId!, media, isAdmin);
      }
    }

    if (originGallery && originGallery.length > 0) {
      await deleteImagesFromGallery(horseId, originGallery, gallery || [], deleteMediaFromGallery, isAdmin);
    }
  } catch (e) {
    formikBag.setFieldError('gallery', retrieveImageError(e));
    return;
  }
  onSuccess && onSuccess();
};

const formikPropsToValues = ({horse, gallery}: OuterProps) => {
  return horse ? {...getHorseEditFields(horse!), gallery: gallery || []} : initialValue;
};

const HorseProfileWithForm = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: formikPropsToValues,
  validationSchema: ({type, isAdmin}: IExternalProps) => getValidationScheme(isAdmin && type === FormType.create),
  handleSubmit,
  enableReinitialize: true,
})(HorseProfileFormik);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {
    horse,
    inconclusiveHealthIssueDictionary,
    horseColorsDictionary,
    breedDictionary,
    disciplineDictionary,
    markingDictionary,
    associationDictionary,
    commercialTypeDictionary,
  } = props;
  const {selectors: inconclusiveHealthIssueSelectors} = inconclusiveHealthIssueDictionary;
  const {selectors: horseColorsSelectors} = horseColorsDictionary;
  const {selectors: breedSelectors} = breedDictionary;
  const {selectors: disciplineSelectors} = disciplineDictionary;
  const {selectors: markingSelectors} = markingDictionary;
  const {selectors: associationSelectors} = associationDictionary;
  const {selectors: commercialTypeSelectors} = commercialTypeDictionary;

  return {
    userDetails: userAdminSelectors.selectUserDetails(state),
    userDetailsLoading: userAdminSelectors.selectCommunication(state, 'userDetailsLoading'),
    healthIssues: inconclusiveHealthIssueSelectors.selectItems(state),
    colors: horseColorsSelectors.selectItems(state),
    breeds: breedSelectors.selectItems(state),
    disciplines: disciplineSelectors.selectItems(state),
    markings: markingSelectors.selectItems(state),
    associations: associationSelectors.selectItems(state),
    commercialTypes: commercialTypeSelectors.selectItems(state),

    gallery: horse ? gallerySelectors.selectGallery(state) : null,
    galleryLoading: gallerySelectors.selectCommunication(state, 'galleryLoading'),
    isLoadingFormData: selectors.selectIsLoadingFormData(state, [
      inconclusiveHealthIssueSelectors.selectCommunication(state, 'itemsLoading'),
      horseColorsSelectors.selectCommunication(state, 'itemsLoading'),
    ]),
  };
};

const mapDispatchToProps = {
  getGallery: galleryActions.getGallery,
  addAvatar: galleryActions.addAvatar,
  deleteAvatar: galleryActions.deleteAvatar,
  addImages: galleryActions.addImages,
  addVideo: galleryActions.addVideo,
  deleteMediaFromGallery: galleryActions.deleteMediaFromGallery,
  getUserDetails: userAdminActions.getUserById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const HorseProfileFormInner = connector(memo(HorseProfileWithForm));
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {inconclusiveHealthIssue, horseColors, breeds, disciplines, markings, associations, commercialTypes} =
    useDictionaries();

  return (
    <DynamicModuleLoader modules={[GalleryModule, HorseModule, AdminUsersModule]}>
      <HorseProfileFormInner
        inconclusiveHealthIssueDictionary={inconclusiveHealthIssue}
        horseColorsDictionary={horseColors}
        breedDictionary={breeds}
        disciplineDictionary={disciplines}
        markingDictionary={markings}
        associationDictionary={associations}
        commercialTypeDictionary={commercialTypes}
        {...externalProps}
      />
    </DynamicModuleLoader>
  );
};

export default Exported;
