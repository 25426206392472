import * as Yup from 'yup';

import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {IImage} from 'Image/models/IImage';

import {Gender} from 'Common/constants/Gender';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {IParentRequest} from 'Horse/models/IHorseRequest';
import {Parentage} from 'HorseProfile/constants/Parentage';

import {initialParentage} from './constants/initialParentage';
import {Nullable} from 'Common/types';
import {isValidHeight} from 'Common/helpers/HeightHelper';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IHorse} from 'Horse/models/IHorse';
import {IWithBirthDate} from 'Common/models/IWithBirthDate';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IAvatared} from 'Common/models/IAvatar';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {IWithHorseMapFieldProps} from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {AnimalType} from '../../../Common/constants/AnimalType';

export interface IFormValues extends IHorseEditFormFields {
  gallery?: IMediaResource[];
}

export interface IParentageField {
  sire?: IParentRequest;
  dam?: IParentRequest;
}

export type IEditHorseFields = Omit<
  IHorse,
  'order' | 'availabilityCriteria' | 'reports' | 'genotypes' | 'isArchived' | 'packages' | 'abilities'
>;

export interface IHorseEditFormFields extends IWithBirthDate, IAvatared, IWithHorseMapFieldProps {
  userId?: number;
  name: string;
  gender: Gender | null;
  commercialTypes?: number[];
  disabledCommercialTypes?: number[];
  comments: string;
  height?: number;
  heightUnit: HeightUnits;
  healthProfile?: number[];
  breeds: number[];
  colors?: number[];
  markings?: number[];
  disciplines?: number[];
  registries?: IRegistryRequest[];
  parentage?: IParentageField;
  temperament: number;
  isPublicProfile: boolean;
  passportNumber: Nullable<string>;
  microchipNumber: Nullable<string>;
  preclinicalNotes: Nullable<string>;
  isAncestryPublicProfile: boolean;
  isCelebrity?: boolean;
  animalType?: AnimalType;
}

export const initialHorse: IHorseEditFormFields = {
  name: '',
  parentage: initialParentage,
  height: '' as any as number,
  heightUnit: HeightUnits.Hands,
  comments: '',
  commercialTypes: [],
  disabledCommercialTypes: [],
  gender: null,
  temperament: 0,
  breeds: [],
  isPublicProfile: false,
  avatar: null,
  dateOfBirth: null,
  microchipNumber: '',
  passportNumber: '',
  userId: undefined,
  location: null,
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  preclinicalNotes: null,
  isAncestryPublicProfile: true,
  isManualAddressMode: false,
};

const registryShape = Yup.array().of(
  Yup.object().shape({
    registrationNumber: Yup.string().nullable(),
    associationId: Yup.number().nullable(),
  })
);

const parentageShape = Yup.object().shape({
  registries: registryShape,
  name: Yup.string().nullable(),
  parent: Yup.mixed<Parentage>(),
});

const baseValidationSchema = Yup.object().shape<IHorseEditFormFields>({
  name: Yup.string().required('Name is required'),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  breeds: Yup.array().of(Yup.number()).required('Breeds are required'),
  colors: Yup.array().of(Yup.number()),
  comments: Yup.string(),
  commercialTypes: Yup.array().of(Yup.number()),
  disabledCommercialTypes: Yup.array().of(Yup.number()),
  disciplines: Yup.array(),
  gender: Yup.mixed<Gender>().required('Sex is required'),
  healthProfile: Yup.array().of(Yup.number()),
  height: Yup.number().test('height checking', '', function (height: number) {
    if (height) {
      const currentUnitLimits = HeightLimits[this.parent.heightUnit];
      const baseErrorMessage = `'Height' must be between ${currentUnitLimits.min} and ${currentUnitLimits.max}. `;
      const additionalErrorMessage = `You entered ${height}. `;
      const additionalHandsErrorMessage = `Also the fractional part must be 1, 2 or 3. ${additionalErrorMessage}. `;

      const isValidHeightUnit = isValidHeight(height, this.parent.heightUnit);
      if (!isValidHeightUnit) {
        if (this.parent.heightUnit === HeightUnits.Hands) {
          return this.createError({message: baseErrorMessage + additionalHandsErrorMessage});
        }

        return this.createError({message: baseErrorMessage + additionalErrorMessage});
      }
    }

    return true;
  }),
  heightUnit: Yup.mixed<HeightUnits>(),
  markings: Yup.array().of(Yup.number()),
  parentage: Yup.object().shape({
    sire: parentageShape,
    dam: parentageShape,
  }),
  registries: registryShape,
  temperament: Yup.number(),
  isPublicProfile: Yup.boolean(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  microchipNumber: Yup.string().nullable(),
  passportNumber: Yup.string().nullable(),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  address: Yup.object()
    .shape<IAddressWithNullableField>({
      country: Yup.string().nullable(),
      state: Yup.string().nullable(),
      city: Yup.string().nullable(),
      street: Yup.string().nullable(),
      zip: Yup.string().nullable(),
    })
    .nullable(),
  preclinicalNotes: Yup.string().nullable(),
  isAncestryPublicProfile: Yup.boolean(),
  isManualAddressMode: Yup.boolean(),
});

const createFromAdminValidationScheme = baseValidationSchema.concat(
  Yup.object().shape<Partial<IHorseEditFormFields>>({
    userId: Yup.number().required('User is required'),
    isCelebrity: Yup.boolean(),
  })
);

export function getValidationScheme(withOwner: boolean = false) {
  return withOwner ? createFromAdminValidationScheme : baseValidationSchema;
}
export const initialValue: IFormValues = {...initialHorse, gallery: []};
