import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IWithBirthDate} from '../../../../Common/models/IWithBirthDate';
import {IAvatared} from '../../../../Common/models/IAvatar';
import {IWithHorseMapFieldProps} from '../../../../Common/components/FormFields/HorseMapField/HorseMapField';
import {DogGender, Gender} from '../../../../Common/constants/Gender';
import {HeightUnits} from '../../../../HorseProfile/constants/HeightUnits';
import {IRegistryRequest} from '../../../../Common/models/IRegistryRequest';
import {Nullable} from '../../../../Common/types';
import {IParentageField} from '../../../../HorseProfile/components/HorseProfileForm/HorseProfileFormInnerValidation';

export interface IFormValues extends IWithBirthDate, IAvatared, IWithHorseMapFieldProps {
  userId?: number;
  name: string;
  gender: Gender | null;
  commercialTypes?: number[];
  disabledCommercialTypes?: number[];
  comments: string;
  height?: number;
  heightUnit: HeightUnits;
  healthProfile?: number[];
  breeds: number[];
  colors?: number[];
  markings?: number[];
  disciplines?: number[];
  registries?: IRegistryRequest[];
  parentage?: IParentageField;
  temperament: number;
  isPublicProfile: boolean;
  passportNumber: Nullable<string>;
  microchipNumber: Nullable<string>;
  preclinicalNotes: Nullable<string>;
  isAncestryPublicProfile: boolean;
  isCelebrity?: boolean;
}

export const initialValues: IFormValues = {
  name: '',
  userId: '',
  breed: '',
  gender: '',
  dateOfBirth: '',
  discipline: '',
};

export const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string().required(REQUIRED_FIELD),
  userId: Yup.string().required(REQUIRED_FIELD),
  breeds: Yup.string().required(REQUIRED_FIELD),
  gender: Yup.mixed<DogGender>().required(REQUIRED_FIELD),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  discipline: Yup.string(),
});
