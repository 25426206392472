import React, {memo, useCallback, useEffect, useState} from 'react';

import {Form, Formik, FormikProps, useFormik, withFormik} from 'formik';
import {validationSchema, IFormValues} from './validation';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {ErrorMessage, MutableField} from 'Common/components/StyledComponents/StyledComponents';

import styled from 'styled-components';

import ModalWindow from '../../../../Common/components/Modal/ModalWindow';

import {castToOption, getStringKeysOption} from '../../../../Common/helpers/OptionHelper';
import {SelectOwner} from '../../../../HorseProfile/components/HorseProfileForm/parts';
import {DogGender, Gender} from '../../../../Common/constants/Gender';
import {useDogUpdate} from '../../helpers/hooks/useDogActions/useDogUpdate';
import {IDog} from '../../../../Dogs/models/IDog';
import {dogBreeds} from 'Common/constants/DogBreeds';
import {dogDisciplines} from 'Common/constants/DogDisciplines';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE} from 'Common/constants/Date';
import moment from 'moment';
import {getEnvParams} from 'Common/helpers/getEnvParams';
import {FaCloudUploadAlt} from 'react-icons/fa';
import {base64ToFile} from 'Admin/shared/helpers/converters/base64-to-file';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertToISO} from 'Common/helpers/DateHelper';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

interface ExternalProps {
  dog: IDog;
  onSuccess(): void;
}

type OuterProps = ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

const {imagesBaseUrl} = getEnvParams();

function makeEdxImageUrl(edxUrlPath: string | null | undefined) {
  if (!edxUrlPath) {
    return '';
  }

  if (edxUrlPath.startsWith('http')) {
    return edxUrlPath;
  }

  return `${imagesBaseUrl}/${edxUrlPath}`;
}

export default function DogEditForm(props: AllProps) {
  const {onSuccess} = props;

  const updateDog = useDogUpdate();
  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string>('');

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (props.dog.dateOfBirth) {
    const frontendDate = moment(props.dog.dateOfBirth).format(FRONTEND_DATE);
    if (frontendDate) {
      props.dog.dateOfBirth = frontendDate.toString();
    }
  }

  const onSelectFile = (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageUrl = reader.result?.toString() || '';
      const imageElement = new Image();
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', (e) => {
        // if (error) setError('')
        const {naturalWidth, naturalHeight} = e.currentTarget as HTMLImageElement;
        if (naturalWidth < 216 || naturalHeight < 144) {
          // setError('Image resolution must be at least 216x144 pixels.')
          setAvatarPreview('');
          return;
        }
        setAvatarPreview(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>Edit Dog</ModalWindowHeader>

      <Formik
        initialValues={props.dog}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          await updateDog({
            id: props.dog.id,
            ownerId: props.dog.owner?.id,
            name: values.name,
            breed: values.breeds,
            gender: values.gender,
            dateOfBirth: values.dateOfBirth ? convertToISO(values.dateOfBirth) : undefined,
            discipline: values.disciplines,
            //sampleId: values.s,
          });

          if (avatarPreview) {
            const convertedAvatar = base64ToFile(avatarPreview, 'avatar');
            const formData = new FormData();
            formData.append('DogId', props.dog.id + '');
            formData.append('File', convertedAvatar);

            await axiosWrapper
              .post(`/dogsadmin-avatar/${props.dog.id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .catch((error) => {
                console.error(error);
              });
          }

          setIsSubmitting(false);
          onSuccess();
        }}
      >
        <Form className="d-flex flex-column justify-content-center">
          <ModalWindowFormContent>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <InputField
                  isRequired={true}
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Dogs name"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
              <MutableField width={50}>
                <InputField
                  isRequired={false}
                  name="sampleId"
                  type="text"
                  label="Sample ID"
                  placeholder="Dogs sample ID"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
            </div>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <DateTimeField isRequired={false} name="dateOfBirth" label="Date of birth" memoized={true} />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isRequired={true}
                  name="gender"
                  label="Sex"
                  options={getStringKeysOption(DogGender)}
                  memoized={true}
                />
              </MutableField>
            </div>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <SelectField
                  isMulti={true}
                  name="breed"
                  label="Breeds"
                  options={getStringKeysOption(dogBreeds)}
                  memoized={false}
                  isRequired={true}
                />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isMulti={true}
                  name="discipline"
                  label="Disciplines"
                  options={getStringKeysOption(dogDisciplines)}
                  memoized={false}
                  isRequired={false}
                />
              </MutableField>
            </div>
            {/* {(avatarPreview || props.dog.avatar?.url) && ( */}
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{width: '200px', maxHeight: '200px'}}
                className="rounded"
                src={
                  !(avatarPreview || props.dog.avatar?.url)
                    ? 'https://placehold.co/216x144?text=Dog+Photo'
                    : avatarPreview || makeEdxImageUrl(props.dog.avatar?.url)
                }
                alt="dog"
              />
              <label htmlFor="avatar-upload" style={{cursor: 'pointer'}}>
                <span className="d-flex align-items-center" style={{gap: '4px'}}>
                  <FaCloudUploadAlt size={20} /> Choose new image
                </span>
              </label>
              <input
                id="avatar-upload"
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{display: 'none'}}
              />
            </div>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Save
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </Formik>
    </>
  );
}
