import useSWR from 'swr';

import {axiosWrapper} from '../../../Common/services/AxiosWrapper';
import {IListResponse} from '../../../Common/models/IResponse';
import {IAdminPermissions} from '../../../Common/models/IAdminPermissions';
import {IBreed} from '../../../Dictionaries/models/IBreed';
import {IDiscipline} from '../../../Dictionaries/models/IDiscipline';

const fetcher = (url) => axiosWrapper.get(url).then((res) => res.data);
export const useDisciplines = (animalType: string) => {
  return useSWR<IListResponse<IDiscipline>>(`/DisciplineItemList?animalType=${animalType}`, fetcher);
};
